.App {
  width: 1040px;
  margin: 0 auto;

  .LoadingGif {
    display: block;
    margin: 48px auto;
  }
}

@media screen and (max-width: 1136px) {
  //1040+48*2=1136
  .App {
    width: 100%;
    padding: 0 48px;
  }
}

@media screen and (max-width: 960px) {
  .App {
    padding: 0 32px;
  }
}

@media screen and (max-width: 600px) {
  //1040+48*2=1136
  .App {
    padding: 0 16px;
  }
}
