@import "../../shared/variables.scss";

.NavigationBar {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  .NavigationLinks * {
    &:first-of-type {
      margin-right: 40px;
    }
  }
}
