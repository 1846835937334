// Color Names
$clr-butteryWhite: #fdfbf6;
$clr-foggyGrey: #d1cebd;
$clr-dawn: #a09e93;

$clr-anzac: #e1b554;
$clr-driftwood: #a88740;
$clr-pesto: #7a6432;
$clr-irishCoffee: #5a4822;

// Buttons/links
$clr-primary: $clr-anzac;
$clr-primary-hover: $clr-driftwood;
$clr-primary-active: $clr-pesto;

$clr-white: $clr-butteryWhite;
$clr-white-hover: $clr-foggyGrey;
$clr-white-active: $clr-dawn;

// Text
$clr-text: black;

// Body font
$fnt-size-big: 24px;

$fnt-size-medium: 20px;

$fnt-size-small: 18px;
