@import "./shared/variables.scss";

* {
  margin: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Rubik", sans-serif;
  font-size: $fnt-size-medium;
  color: black;
  background-color: $clr-white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2 {
  font-family: "Quando", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  font-weight: normal;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 40px;
}

h3,
h4 {
  font-family: "Rubik", sans-serif;
  font-weight: normal;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: $fnt-size-medium;
  font-weight: 600;
  opacity: 0.7;
  line-height: 1.3;
  margin-bottom: 4px;
}

p {
  line-height: 1.75;
  font-weight: 300;
}

a {
  font-weight: 500;
}

@media (hover: hover) and (pointer: fine) {
  a {
    &:hover {
      color: $clr-primary-hover;
    }
    &:active {
      color: $clr-primary-active;
    }
  }
}

@media screen and (max-width: 600px) {
  body {
    font-size: $fnt-size-small;
  }

  p {
    font-size: $fnt-size-small;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: $fnt-size-small;
  }
}
